import React from "react";
import styles from "./ShareButtons.module.scss";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  GooglePlusShareButton,
  GooglePlusIcon,
  EmailShareButton,
  EmailIcon
} from "react-share";

export default function ShareButtons({ url }) {
  return (
    <div className={styles.shareContainer}>
      <div className={styles.callToAction}>Del: </div>
      <div className={styles.shareButtons}>
        <FacebookShareButton
          url={url}
          style={{ cursor: "pointer" }}
          additionalProps={{ "aria-label": "Facebook" }}
        >
          <FacebookIcon size={32} />
        </FacebookShareButton>
        <TwitterShareButton
          url={url}
          style={{ cursor: "pointer" }}
          additionalProps={{ "aria-label": "Twitter" }}
        >
          <TwitterIcon size={32} />
        </TwitterShareButton>
        <LinkedinShareButton
          url={url}
          style={{ cursor: "pointer" }}
          additionalProps={{ "aria-label": "LinkedIn" }}
        >
          <LinkedinIcon size={32} />
        </LinkedinShareButton>
        <GooglePlusShareButton
          url={url}
          style={{ cursor: "pointer" }}
          additionalProps={{ "aria-label": "Google Plus" }}
        >
          <GooglePlusIcon size={32} />
        </GooglePlusShareButton>
        <EmailShareButton
          url={url}
          style={{ cursor: "pointer" }}
          additionalProps={{ "aria-label": "Epost" }}
        >
          <EmailIcon size={32} />
        </EmailShareButton>
      </div>
    </div>
  );
}
