import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

import styles from "./SidebarBrand.module.scss";

import Button from "../Button";

const SIDEBAR_QUERY = graphql`
  query SidebarQuery {
    logo: file(relativePath: { eq: "logo/Brillante_Logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 272, maxHeight: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

export default function SidebarBrand() {
  return (
    <StaticQuery
      query={SIDEBAR_QUERY}
      render={data => (
        <div className={styles.SidebarBrand}>
          <Img fluid={data.logo.childImageSharp.fluid} />
          <div className={styles.brillanteDescription}>
            Bryllup? Jubileum? Konsert på arbeidsplassen eller i privat selskap?
            Brillante har profesjonelle musikere til enhver anledning!
          </div>
          <div className={styles.buttonContainer}>
            <Button
              className={styles.button}
              component={Link}
              width="100%"
              to="/#events"
            >
              Les mer
            </Button>
          </div>
        </div>
      )}
    />
  );
}
