import React from "react";
import Helmet from "react-helmet";

export default function BlogPostHelmet({ frontmatter, location, url }) {
  return (
    <Helmet titleTemplate="%s | Brillante musikerformidling">
      <title>{`${frontmatter.title}`}</title>
      <meta property="description" content={`${frontmatter.description}`} />
      <meta property="og:type" content="article" />
      <meta property="og:description" content={`${frontmatter.description}`} />
      <meta property="og:title" content={`${frontmatter.title}`} />
      <meta
        property="og:image"
        content={`${frontmatter.cloudinary_image.image}`}
      />
      {/* <meta property="og:image:width" content="960" /> */}
      {/*
      <meta
        property="og:image:height"
        content={frontmatter.cloudinary_image.image}
      /> */}
      <meta
        property="og:image:alt"
        content={
          frontmatter.cloudinary_image.alt
            ? frontmatter.cloudinary_image.alt
            : ""
        }
      />

      <meta property="og:url" content={`${location}`} />
    </Helmet>
  );
}
