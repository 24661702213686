import React from "react";

// Image component that accepts both Gatsby ImageSharp data with gatsby-image
// and regluar image URL with a regular <img>-tag. Also takes alt tag and
// styles into consideration

export default function Image({ cloudinaryImage, className }) {
  if (cloudinaryImage && cloudinaryImage.image) {
    return (
      <img
        className={className}
        loading="lazy"
        src={cloudinaryImage.image}
        alt={cloudinaryImage.alt}
      ></img>
    );
  } else {
    return null;
  }
}
