import React, { Fragment } from "react";
import { Link } from "gatsby";
import styles from "./Breadcrumb.module.scss";
import Img from "gatsby-image";

export default function PageHeader({ input, backgroundImage }) {
  return (
    <header className={styles.header}>
      <div className={styles.container}>
        {backgroundImage ? (
          <div className={styles.background}>
            <Img
              fluid={backgroundImage}
              style={{ width: "100%", height: "100%" }}
              imgStyle={{ objectFit: "cover", objectPosition: "50% 30%" }}
            />
          </div>
        ) : null}
        <ul className={styles.input}>
          <li>
            <Link to="/">Brillante</Link>{" "}
          </li>
          <span className={styles.caret}>></span>
          {input.map((item, index) => (
            <Fragment key={index}>
              <li>
                {item.link ? (
                  <Link to={item.link}>{item.title}</Link>
                ) : (
                  item.title
                )}
              </li>
              <span className={styles.caret}>></span>
            </Fragment>
          ))}
        </ul>
      </div>
    </header>
  );
}
