import React from "react";
import styles from "./Button.module.scss";

function Button({
  children,
  width,
  className,
  style,
  component: Component = "button",
  href,
  type,
  disabled,
  to,
  outline,
  target
}) {
  return (
    <Component
      className={`${
        outline ? `${styles.outlineButton} ${styles.button}` : styles.button
      } ${className}`}
      style={{ ...style, width: width ? width : "default" }}
      href={href}
      type={type}
      disabled={disabled}
      to={to}
      target={target}
      rel={target === "_blank" ? `rel = "noopener noreferrer"` : null}
    >
      <span>{children}</span>
    </Component>
  );
}

export default Button;
