import React from "react";
import { graphql } from "gatsby";
import BlogPostHelmet from "../components/Helmets/BlogPostHelmet";

import Layout from "../components/Layout/Layout";
import Header from "../components/Layout/Breadcrumb";
import Image from "../components/Image";
import styles from "./blog-post.module.scss";
import Content, { HTMLContent } from "../components/Content";
import SidebarBrand from "../components/Layout/SidebarBrand";
import ShareButtons from "../components/Blog/ShareButtons";

function ImageFigure({ children, caption, credit }) {
  return (
    <>
      <figure className={styles.figure}>
        {children}

        {credit ? (
          <figcaption style={{ textAlign: "center", fontStyle: "italic" }}>
            Foto: {credit ? credit : null}
          </figcaption>
        ) : null}
      </figure>
    </>
  );
}

const BlogPost = ({ data, location = {} }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <Header
        input={[
          { title: "Blogg", link: "blogg" },
          { title: post.frontmatter.title }
        ]}
      />
      <BlogPostTemplate
        content={post.html} // GraphQL data
        contentComponent={HTMLContent} // CMS data
        description={post.frontmatter.description}
        cloudinaryImage={post.frontmatter.cloudinary_image}
        helmet={
          <BlogPostHelmet
            frontmatter={post.frontmatter}
            location={location.href}
            url={data.site.siteMetadata.siteUrl}
          />
        }
        title={post.frontmatter.title}
        sidebarTitle={
          post.frontmatter.sidebar
            ? post.frontmatter.sidebar.sidebar_title
            : null
        }
        sidebarContent={
          post.frontmatter.sidebar ? post.frontmatter.sidebar.html : null
        }
        url={location.href}
      />
    </Layout>
  );
};

export default BlogPost;

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  title,
  helmet,
  sidebarTitle,
  sidebarContent,
  cloudinaryImage,
  url
}) => {
  const PostContent = contentComponent || Content;
  return (
    <>
      <section id="blogg" className={styles.section}>
        {helmet || ""}
        <article className={styles.article}>
          <div className={styles.mainImageContainer}>
            <ImageFigure credit={cloudinaryImage.credit}>
              <Image
                className={styles.mainImage}
                cloudinaryImage={cloudinaryImage}
              />
            </ImageFigure>
          </div>
          <h1 className={styles.title}>{title}</h1>
          <div className={styles.preamble}>{description}</div>
          <ShareButtons url={url} />
          <div
            className={content === "" ? styles.noArticle : styles.contentGrid}
          >
            <div className={styles.content}>
              <PostContent content={content} />
            </div>
            <div className={styles.sidebar}>
              {sidebarContent && sidebarContent.length > 1 ? (
                <div className={styles.sidebarContent}>
                  {sidebarTitle ? <h3>{sidebarTitle}</h3> : null}
                  <div>
                    <PostContent content={sidebarContent} />
                  </div>
                </div>
              ) : null}
              <SidebarBrand />
            </div>
          </div>
          <div style={{ paddingBottom: "10rem" }} />
        </article>
      </section>
    </>
  );
};

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        cloudinary_image {
          image
          alt
        }

        sidebar {
          sidebar_title
          html
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
